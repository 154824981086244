import axios from '@tenant/core/init/axios'
import { sameOrigin } from '@tenant/utils/helper'

const cacheImages = {
    images: {},

    /**
     * @param {string} key
     * @param value
     */
    setCacheImage(key, value) {
        if (!this.images[key]) {
            this.images[key] = value
        }

        return value
    },

    /**
     * @param {string} key
     */
    getCacheImage(key) {
        return this.images[key]
    },
}

export const useFileStorage = () => {
    const emitter = useEmitter()

    const state = reactive({
        src: null,
        base64File: null,
        loaded: false,
    })

    const controller = new AbortController()

    const getFileContent = (url, options = {}) => {
        return axios.get(url, {
            signal: controller.signal,
            responseType: 'blob',
            ...options,
        })
    }

    const downloadFile = async (url, fileName, options = {}) => {
        const content = await getFileContent(url, options)
        createFileDownload(content, fileName)
    }

    const setLoaded = (loaded) => {
        state.loaded = loaded
    }

    onMounted(() => {
        emitter.on('image-cache', onEmitImageCache)
    })

    onUnmounted(() => {
        emitter.off('image-cache', onEmitImageCache)
    })

    const onEmitImageCache = ({ src, base64File }) => {
        if (src !== state.src) {
            return
        }

        state.base64File = base64File
        controller.abort()
    }

    const loadFile = async (src) => {
        try {
            if (!src) {
                setLoaded(true)
                return
            }

            state.src = src
            let base64File = cacheImages.getCacheImage(src)
            if (!base64File) {
                setLoaded(false)

                if (sameOrigin(src, window.location)) {
                    const content = await getFileContent(src)
                    base64File = await getBase64File(content)
                } else {
                    base64File = src
                }

                cacheImages.setCacheImage(src, base64File)
                emitter.emit('image-cache', { src, base64File })
            }

            state.base64File = base64File
        } finally {
            state.src = null
            setLoaded(true)
        }
    }

    const createFileDownload = (blob, fileName) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(blob)

        // create "a" HTML element with href to file & click
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
    }

    const getBase64File = async (blob) => {
        const reader = new FileReader()

        await new Promise((resolve, reject) => {
            reader.onload = resolve
            reader.onerror = reject
            reader.readAsDataURL(blob)
        })
        return reader.result
    }

    return {
        ...toRefs(state),
        setLoaded,
        loadFile,
        downloadFile,
        getBase64File,
    }
}
